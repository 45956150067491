import React from 'react'
import HeroBigBG from "../../../Assets/HeroBG.jpg";

const ComponentBG = () => {
  return (
    <div
      className="h-[250px] w-full"
      style={{
        background: `url(${HeroBigBG})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
        <div className='w-full h-full bg-[rgba(0,0,0,0.5)]'></div>
    </div>
  );
}

export default ComponentBG