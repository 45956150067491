import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <>
      <div
        className="relative z-0 flex-wrap min-h-screen gap-2 md:-mt-10 flex-center-center"
        style={{
          background: "url('/images/hero-bg-pattern.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
      >
        <div className="absolute top-0 right-0 rounded-full bg-[rgb(44,48,147)]/30 dark:bg-[rgb(44,48,147)]/50 w-72 h-72 -z-10 blur-[120px]"></div>
        <div className="flex-1 basis-[20rem]">
          <h1 className="text-4xl font-bold capitalize md:text-5xl max-w-[600px]">
            Navigating Real Estate's future together
          </h1>
          <div className="pl-3 mt-5 mb-10 border-l-4 border-[rgb(235,30,36)]">
            <p>
              At <strong>Idi Mfon Consults</strong>, our mission is to empower
              our clients with robust, data-driven real estate solutions that
              inspire confidence and create sustainable value. We are dedicated
              to fostering a culture of integrity, professionalism, and
              innovation in every project we undertake, ensuring that every
              client receives unparalleled service and insight.
            </p>
          </div>

          <div className="pl-3 mt-5 border-l-4 border-[rgb(235,30,36)]">
            <h2 className="text-2xl font-semibold">Vision</h2>
            <p>
              Our vision is to become the most trusted estate surveying and
              valuation firm in Nigeria, recognized for our commitment to client
              success and our ability to deliver results that stand the test of
              time. We strive to set new benchmarks in the real estate industry
              through our focus on quality, client satisfaction, and ethical
              practices.
            </p>
          </div>

          <div className="flex gap-3 max-sm:flex-col">
            <Link
              to="/contact"
              className="mt-4 btn btn-primary block text-center min-w-[250px]"
            >
              Get a free consultation
            </Link>
            <Link
              to="/"
              className="mt-4 btn btn-primary block text-center min-w-[250px]"
            >
              Browse Properties
            </Link>
          </div>
          {/* <div className="mt-6 text-center flex-align-center gap-x-6">
            <div>
              <h1 className="text-2xl font-bold">
                12k <span className="text-sm text-[rgb(235,30,36)]">+</span>
              </h1>
              <p>Property Valuations Conducted</p>
            </div>
            <div>
              <h1 className="text-2xl font-bold">
                15k <span className="text-sm text-[rgb(235,30,36)]">+</span>
              </h1>
              <p>Successful Project Completions</p>
            </div>
            <div>
              <h1 className="text-2xl font-bold">
                100 <span className="text-sm text-[rgb(235,30,36)]">+</span>
              </h1>
              <p>Satisfied Clients and Growing</p>
            </div>
          </div> */}
        </div>
        <div className="flex-1 basis-[20rem]">
          <img
            src="/images/hero-4.png"
            alt="Real estate services"
            className="w-full"
          />
        </div>
      </div>
    </>
  );
};

export default Hero;
