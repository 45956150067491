import ComponentBG from "../components/common/page-componets/ComponentBG";
import { ContactInfo, Form, Map } from "../components/contact";

const Contact = () => {
  return (
    <div className="pt-16">
      <ComponentBG/>
      <div className="mt-20 px-[3%] md:px-[6%] max-w-[1400px] mx-auto">
        <Map />
        <ContactInfo />
        <Form />
      </div>
    </div>
  );
};

export default Contact;
