import {
  Brands,
  Counter,
  Featured,
  Projects,
  Services,
  Team,
  Testimonial,
} from "../components/common/page-componets";
import {
  Feeds,
  Filters,
  Hero,
  Invest,
  Speciality,
} from "../components/home/home-1";

const Home = () => {
  return (
    <div className="pt-20 px-[3%] md:px-[6%]">
      <Hero />
      {/* <Filters /> */}
      {/* <Invest /> */}
      {/* <Speciality /> */}
      <Services />
      {/* <Featured /> */}
      {/* <Counter /> */}
      {/* <Projects /> */}
      {/* <Testimonial /> */}
      <Brands />
      <Team/>
      {/* <Feeds /> */}

    </div>
  );
};

export default Home;
