import { brands } from "../../../data/dummyData";

const Brands = () => {
  return (
    <div className="pt-6 pb-10 px-[3%] md:px-[6%]">
      <div className="text-center max-w-[400px] mx-auto">
        <h1 className="mx-auto sub-heading">Our Partners</h1>
        <h1 className="heading">Trusted Brands</h1>
        <p className="mt-3">
          We are proud to collaborate with reputable brands known for their
          commitment to quality and innovation, enhancing our offerings for your
          benefit.
        </p>
      </div>
      <div className="flex  mt-8 flex-center-center ">
        {brands.map((image, i) => (
          <div className="group" key={i}>
            <img
              src={image}
              alt="Brand logo"
              className="w-[80%] group-hover:scale-125 transition-a"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Brands;
