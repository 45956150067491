export const navLinks = [
  {
    id: 1,
    linkText: "Home",
    url: "/",
    // subLinks: [
    //   {
    //     id: 1,
    //     linkText: "Home 1",
    //     url: "/",
    //   },
    //   {
    //     id: 2,
    //     linkText: "Home 2",
    //     url: "/home-2",
    //   },
    //   {
    //     id: 3,
    //     linkText: "Home 3",
    //     url: "/home-3",
    //   },
    // ],
  },
  {
    id: 2,
    linkText: "About Us",
    url: "/about-us",
    subLinks: [
      {
        id: 1,
        linkText: "About Us",
        url: "/about-us",
      },
      {
        id: 2,
        linkText: "Vision & Mission",
        url: "/about-2",
      },
    ],
  },
  {
    id: 3,
    linkText: "Services",
    url: "/services",
    // subLinks: [
    //   {
    //     id: 1,
    //     linkText: "Services 1",
    //     url: "/services",
    //   },
    //   {
    //     id: 2,
    //     linkText: "Services 2",
    //     url: "/services-2",
    //   },
    // ],
  },
  // {
  //   id: 4,
  //   linkText: "Property",
  //   url: "/property",
  //   subLinks: [
  //     {
  //       id: 1,
  //       linkText: "Property 1",
  //       url: "/property",
  //     },
  //     {
  //       id: 2,
  //       linkText: "Property 2",
  //       url: "/property-2",
  //     },
  //     {
  //       id: 3,
  //       linkText: "Property 3",
  //       url: "/property-3",
  //     },
  //     {
  //       id: 4,
  //       linkText: "Property 4",
  //       url: "/property-4",
  //     },
  //     {
  //       id: 5,
  //       linkText: "Property 5",
  //       url: "/property-5",
  //     },
  //     {
  //       id: 6,
  //       linkText: "Property 6",
  //       url: "/property-6",
  //     },
  //   ],
  // },
  // {
  //   id: 5,
  //   linkText: "Blog",
  //   url: "/blog",
  //   subLinks: [
  //     {
  //       id: 1,
  //       linkText: "Blog 1",
  //       url: "/blog",
  //     },
  //     {
  //       id: 2,
  //       linkText: "Blog 2",
  //       url: "/blog-2",
  //     },
  //     {
  //       id: 3,
  //       linkText: "Blog 3",
  //       url: "/blog-3",
  //     },
  //     {
  //       id: 4,
  //       linkText: "Blog 4",
  //       url: "/blog-4",
  //     },
  //   ],
  // },
  {
    id: 6,
    linkText: "Team",
    url: "/our-team",
  },
  {
    id: 7,
    linkText: "Contact",
    url: "/contact",
  },
  // {
  //   id: 8,
  //   linkText: "Pages",
  //   url: "/portifolio",
  //   subLinks: [
  //     {
  //       id: 1,
  //       linkText: "Portiflio-1",
  //       url: "/portifolio",
  //     },
  //     {
  //       id: 2,
  //       linkText: "Portiflio-2",
  //       url: "/portifolio-2",
  //     },
  //     {
  //       id: 3,
  //       linkText: "Team",
  //       url: "/team",
  //     },
  //     {
  //       id: 4,
  //       linkText: "Faq",
  //       url: "/faqs",
  //     },
  //     {
  //       id: 5,
  //       linkText: "PageNotFound",
  //       url: "/404",
  //     },
  //   ],
  // },
];
