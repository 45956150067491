import {
  AboutUs,
  Brands,
  Feeds,
  Team,
  Testimonial,
} from "../components/common/page-componets";
import ComponentBG from "../components/common/page-componets/ComponentBG";

const About = () => {
  return (
    <div className="pt-16">
      <ComponentBG />
      <div className="px-[3%] md:px-[6%]">
        <AboutUs />
      </div>
      {/* <Team /> */}
      {/* <Testimonial /> */}
      {/* <Brands /> */}
      {/* <Feeds /> */}
    </div>
  );
};

export default About;
