const Map = () => {
  return (
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3974.582581836212!2d7.916405774171181!3d5.008770394967669!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1067f87efdda59df%3A0x109bd923e5f4ee10!2sSacred%20Heart%20Catholic%20Church!5e0!3m2!1sen!2sng!4v1731244676761!5m2!1sen!2sng"
        frameborder="0"
        className="w-full h-[450px]"
      ></iframe>
    </div>
  );
};

export default Map;
