import {
  Categories,
  Counter,
  Feeds,
  Services as ServicesList,
  Team,
} from "../components/common/page-componets";
import ComponentBG from "../components/common/page-componets/ComponentBG";

const Services = () => {
  return (
    <div className="pt-16">
      <ComponentBG/>
      <div className="pt-20 px-[3%] md:px-[6%]">
        <ServicesList />
      </div>
      {/* <Categories /> */}
      {/* <Counter />
      <Team />
      <Feeds /> */}
    </div>
  );
};

export default Services;
