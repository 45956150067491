import { OverView, Stats } from "../components/about";
import { Brands, Feeds, WhatWeDo } from "../components/common/page-componets";
import ComponentBG from "../components/common/page-componets/ComponentBG";

const AboutTwo = () => {
  return (
    <div className="pt-16">
      <ComponentBG />
      <div className="px-[3%] md:px-[6%]">
        <Stats />
      </div>
      {/* <OverView />
      <Brands />
      <WhatWeDo />
      <Feeds /> */}
    </div>
  );
};

export default AboutTwo;
