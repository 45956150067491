import { BiMap } from "react-icons/bi";
import { FiMail, FiPhone } from "react-icons/fi";

const ContactInfo = () => {
  return (
    <div className="grid grid-cols-1 gap-6 py-16 sm:grid-cols-2 md:grid-cols-3">
      <div className="text-center">
        <div className="icon-box !h-14 !w-14 !bg-[rgb(235,30,36)] text-white mx-auto text-2xl">
          <FiPhone />
        </div>
        <h1 className="mt-2 text-lg font-semibold">Phone Number</h1>
        <p>
          <a href="tel:+234916870781" className="hover:underline hover:text-[rgb(235,30,36)]">
            +234 916 870781
          </a>
        </p>
      </div>
      <div className="text-center">
        <div className="icon-box !h-14 !w-14 !bg-[rgb(235,30,36)] text-white mx-auto text-2xl">
          <FiMail />
        </div>
        <h1 className="mt-2 text-lg font-semibold">Email Address</h1>
        <p>
          <a
            href="mailto:idimfonconsults@gmail.com"
            className="hover:underline hover:text-[rgb(235,30,36)]"
          >
            idimfonconsults@gmail.com
          </a>
        </p>
      </div>
      <div className="text-center">
        <div className="icon-box !h-14 !w-14 !bg-[rgb(235,30,36)] text-white mx-auto text-2xl">
          <BiMap />
        </div>
        <h1 className="mt-2 text-lg font-semibold">Office Address</h1>
        <p>
          8A Aka/Nnung Udoe Road Opposite Sacred Heart Catholic Church, Uyo,
          Akwa Ibom State, Nigeria.
        </p>
      </div>
    </div>
  );
};

export default ContactInfo;
