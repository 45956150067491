/* eslint-disable jsx-a11y/anchor-is-valid */

import { BiBuildingHouse } from "react-icons/bi";
import { FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";
import { Link } from "react-router-dom";
import { logo } from "../../data/dummyData";
import mfonWhite from "../../Assets/mfon-white.jpeg"

const Footer = () => {
  return (
    <div className="text-slate-200 tex">
      <footer className="">
        <div className="flex justify-center gap-2  mx-auto flex-wrap">
          <div className="w-[30%]">
            {/* <Link to="/" className="flex-shrink-0 flex-align-center gap-x-1">
              <div className="group">
                <img src={mfonWhite} alt="" className="w-24" />
              </div>
            </Link> */}
            <h2 className="text-xl font-semibold">About Us</h2>
            <div className="mt-3">
              <p className="text-sm max-w-[300px]">
                Based in Nigeria, Idi Mfon Consults is a premier Estate
                Surveying and Valuation firm committed to delivering excellence
                and precision in every aspect of real estate services.{" "}
                <Link to="/about-us" className="text-[rgb(235,30,36)] hover:underline font-semibold">
                  Learn More
                </Link>
              </p>
              <div className="gap-5 my-6 flex">
                <div className="icon-box bg-dark-light hover:bg-hover-color-dark">
                  <FiFacebook />
                </div>

                <div className="icon-box bg-dark-light hover:bg-hover-color-dark">
                  <FaTwitter />
                </div>

                <div className="icon-box bg-dark-light hover:bg-hover-color-dark">
                  <FaInstagram />
                </div>

                <div className="icon-box bg-dark-light hover:bg-hover-color-dark">
                  <FaLinkedin />
                </div>
              </div>
            </div>
          </div>

          <div className="w-[30%] ml-20 max-sm:hidden">
            <h2 className="text-xl font-semibold">Services</h2>
            <ul>
              <li className="my-3 text-muted">
                <Link to="/services">Luxury Apartments</Link>
              </li>
              <li className="my-3 text-muted">
                <Link to="/services">Architectural Design</Link>
              </li>
              <li className="my-3 text-muted">
                <Link to="/services">Home Remodeling</Link>
              </li>
              <li className="my-3 text-muted">
                <Link to="/services">Office Renovation</Link>
              </li>
              <li className="my-3 text-muted">
                <Link to="/services">24/7 Customer Support</Link>
              </li>
            </ul>
          </div>

          <div className="w-[30%] max-sm:ml-20">
            <h2 className="text-xl font-semibold">Quick Links</h2>
            <ul>
              <li className="my-3 text-muted">
                <Link to="/">Home</Link>
              </li>
              <li className="my-3 text-muted">
                <Link to="/about-us">About Us</Link>
              </li>
              <li className="my-3 text-muted">
                <Link to="/services">Services</Link>
              </li>
              <li className="my-3 text-muted">
                <Link to="/property">Property</Link>
              </li>
            </ul>
          </div>

          {/* <div className="flex-1 basis-[10rem]">
            <h2 className="text-xl font-semibold">Business</h2>
            <ul>
              <li className="my-3 text-muted">
                <a href="#"> Success</a>
              </li>
              <li className="my-3 text-muted">
                <a href="#">Guide</a>
              </li>
              <li className="my-3 text-muted">
                <a href="#">Mission</a>
              </li>
              <li className="my-3 text-muted">
                <a href="#">Terms & Conditions</a>
              </li>
              <li className="my-3 text-muted">
                <a href="#">Pricacy Policy</a>
              </li>
            </ul>
          </div> */}

          {/* <div className="flex-1 basis-[10rem] text-center md:text-left">
            <h2 className="text-xl font-semibold">
              Subscribe to our Newsletter
            </h2>
            <p className="text-sm text-muted">
              Subscribe to be the first one to know about updates. Enter your
              email
            </p>
            <div className="justify-center my-3 flex-align-center">
              <input
                type="text"
                className="px-4 py-[0.35rem] card-bordered dark:shadow-none outline-none bg-transparent rounded-lg border-dark"
                placeholder="Email Address.."
              />
              <button className="-ml-2 btn btn-primary">subscribe</button>
            </div>
          </div> */}
        </div>
      </footer>
    </div>
  );
};

export default Footer;
