const Form = () => {
  return (
    <div className="pt-10 pb-8">
      <div className="flex-col gap-4 flex-align-center sm:flex-row">
        <div className="flex-1 w-full">
          <p>First Name</p>
          <input
            type="text"
            className="w-full input rounded"
            placeholder="First Name.."
          />
        </div>
        <div className="flex-1 w-full">
          <p>Last Name</p>
          <input
            type="text"
            className="w-full input rounded"
            placeholder="Last Name.."
          />
        </div>
      </div>
      <div className="flex-col gap-4 flex-align-center sm:flex-row mt-4">
        <div className="flex-1 w-full">
          <p>Email Address</p>
          <input
            type="text"
            className="w-full input rounded"
            placeholder="Email Address.."
          />
        </div>
        <div className="flex-1 w-full">
          <p>Phone Number</p>
          <input
            type="tel"
            className="w-full input rounded"
            placeholder="Your Phone"
          />
        </div>
      </div>
      <div className="mt-4">
        <p>Message</p>
        <textarea
          rows={4}
          className="w-full input rounded"
          placeholder="Message.."
        ></textarea>
      </div>
      <div className="mt-4 flex-center-center">
        <button className="btn btn-primary min-w-[250px]">submit</button>
      </div>
    </div>
  );
};

export default Form;
